import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOffer } from '../../models/market';
import { Token } from '../../models/tokens';
import { MarketState } from './types';

const initialState: MarketState = {
  offerTokens: [],
  saleTokens: [],
  offers: []
}

export const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {
    setMarketOfferTokens: (state, action: PayloadAction<Token[]>) => {
      state.offerTokens = action.payload
    },
    setMarketSaleTokens: (state, action: PayloadAction<Token[]>) => {
      state.saleTokens = action.payload
    },
    setMarketOffers: (state, action: PayloadAction<IOffer[]>) => {
      state.offers = action.payload
    },
  }
})

export const {
  setMarketOfferTokens,
  setMarketSaleTokens,
  setMarketOffers
} = marketSlice.actions;

export default marketSlice.reducer;