import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step, StepLabel,
  Stepper,
} from '@mui/material';
import { BigNumber } from 'ethers';
import React, { Fragment, useMemo } from 'react';
import Slide from '../../../../components/Transitions/Slide';
import { TokenType } from '../../../../models/tokens';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import {
  selectOfferStep,
  selectOfferToken,
  selectOfferType,
  selectOfferSaleAmount,
  selectOfferStep2Complete, selectOfferCreate,
} from '../../../../state/offer/hooks';
import { setOfferCreate, setOfferStep } from '../../../../state/offer/reducer';
import { selectUserBalances } from '../../../../state/user/hooks';
import ApproveTokens from './steps/ApproveToken';
import SelectType from './steps/SelectType';
import CreateOffer from './steps/CreateOffer';

const CreateOfferDialog = () => {
  const activeStep = useAppSelector(selectOfferStep)
  const selectedToken = useAppSelector(selectOfferToken)
  const selectedType = useAppSelector(selectOfferType)
  const selectedSaleAmount = useAppSelector(selectOfferSaleAmount)
  const balances = useAppSelector(selectUserBalances);
  const dispatch = useAppDispatch()
  const step2Complete = useAppSelector(selectOfferStep2Complete)
  const locked = selectedType === TokenType.ERC20Locked
  const open = useAppSelector(selectOfferCreate);
  const steps = [
    'Select',
    'Approve',
    'Create',
    'Fund',
  ].filter(s => locked ? s !== 'Approve' : s !== 'Fund')

  const balance = useMemo(() => {
    return balances[selectedToken?.address]?.[selectedType === TokenType.ERC20Locked ? 'locked' : 'balance'] || BigNumber.from(0)
  }, [selectedToken, selectedType, balances])

  const sections = [SelectType, locked ? CreateOffer : ApproveTokens, locked ? CreateOffer : CreateOffer]
  const handleNext = () => {
    dispatch(setOfferStep(activeStep + 1))
  }
  const handleBack = () => {
    dispatch(setOfferStep(activeStep - 1))
  }
  const handleClose = () => {
    dispatch(setOfferStep(0))
    dispatch(setOfferCreate(false))
  }
  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      keepMounted
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '16px'
        }
      }}
    >
      <DialogTitle align="center">Create New Offer</DialogTitle>
      <Box sx={{ position: 'absolute', top: 12, right: 12 }} onClick={handleClose}>
        <Close style={{ width: "30px", height: '30px' }} />
      </Box>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {sections.map((Section, i) => (
          <Fragment key={i}>
            {activeStep === i && <Section />}
          </Fragment>
        ))}
      </DialogContent>
      <DialogActions sx={{ justifyContent: activeStep === 0 ? 'right' : 'space-between', paddingX: 4, paddingTop: 0, paddingBottom: 3 }}>
        {activeStep !== 0 && (
          <Button variant="contained" color="secondary" onClick={handleBack}>Back</Button>
        )}
        <Button
          disabled={balance.eq(0) || !Number(selectedSaleAmount) || (activeStep === 1 && !step2Complete)}
          variant="contained"
          color="secondary"
          onClick={handleNext}>Next</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateOfferDialog;