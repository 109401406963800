import { BigNumber } from 'ethers';

export enum TokenType {
  ERC20Locked,
  ERC20,
  ERC1155
}

export interface Token {
  name: string
  symbol: string
  decimals: number
  address: string
  type: TokenType
}

export interface UserToken extends Token {
  approved: boolean
  balance: BigNumber
  locked: BigNumber
  cooldown: number
}