import {
  Avatar,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
} from '@mui/material';
import React, { FC, Fragment, useState } from 'react';
import CreateOffer from './components/CreateOffer';
import CancelOffer from './components/CancelOffer';
import Logo from '../../assets/images/old-logo.jpg';
import DataGrid from './components/grid'
const TableCell2: FC<TableCellProps> = (props) => {
  return (
    <TableCell {...props} sx={{ borderBottom: 0, padding: 0,  }}>

    </TableCell>
  )
}
const Market = () => {
  const [cancelOfferOpen, setCancelOfferOpen] = useState(true);
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))

  const volume = [
    { chain: "Harmony", volume: "$9,497,209" },
    { chain: "Klaytn", volume: "TBD" },
    { chain: "DFK Chain", volume: "TBD" }
  ]
  return (
    <Stack spacing={2} sx={{ paddingY: 4, width: sm ? 0.95 : 0.8, maxWidth: "md",  alignItems: 'center' }}>
      <Typography variant="h3" sx={{ textAlign: 'center' }}>
        The Leading OTC Marketplace
      </Typography>
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        Trade Your Tokens and Items Safely with the Power of Smart Contracts
      </Typography>
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Avatar sx={{ minWidth: '72px', minHeight: '72px' }} src={Logo}/>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>Total Traded Volume</Typography>
        <Avatar sx={{ minWidth: '72px', minHeight: '72px' }} src={Logo}/>
      </Stack>
      <Grid container>
        {volume.map(({ chain, volume }, i) => (
          <Fragment key={i}>
            <Grid item xs={12} sm={6} sx={{ borderRight: sm ? '' : '1px solid' }}>
              <Typography variant="h6" align={sm ? 'center' : 'right'} marginRight={sm ? 0 : 1}>
                {chain}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" align={sm ? 'center' : 'left'} marginLeft={sm ? 0 : 1}>
                {volume}
              </Typography>
            </Grid>
          </Fragment>
        ))}
      </Grid>

      <DataGrid />
      <Typography sx={{ fontStyle: "italic", fontSize: '10px', textAlign: 'center' }}>
        "It wasn't a big deal, they said. Barely anyone knew about it, they said. Only extremely
        technical users would even be able to use the exploit, they said" - Someone</Typography>
      <CreateOffer />
      <CancelOffer open={cancelOfferOpen} onClose={() => setCancelOfferOpen(false)} />
    </Stack>
  )
}

export default Market;