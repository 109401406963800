import { ThemeProvider } from '@mui/material';
import { ConnectKitProvider } from 'connectkit';
import React from 'react';
import ReactDOM from 'react-dom/client';
import "@fontsource/noto-sans"
import './index.css';
import { Provider } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import { client } from './constants/wagmi';
import App from './pages/App';
import FAQ from './pages/faq';
import Market from './pages/market';
import reportWebVitals from './reportWebVitals';
import { WagmiConfig } from 'wagmi';
import store from './state';
import Updaters from './state/updaters';
import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Market />} />
      <Route path="faq" element={<FAQ />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Route>,

  )
)

root.render(
  <React.StrictMode>
    <WagmiConfig client={client}>
      <ConnectKitProvider options={{ walletConnectName: 'WalletConnect' }}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Updaters />
            <RouterProvider router={router} />
          </ThemeProvider>
        </Provider>
      </ConnectKitProvider>
    </WagmiConfig>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
