import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { BigNumber } from 'ethers';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { TokenType } from '../../../../../models/tokens';
import { useAppDispatch, useAppSelector } from '../../../../../state/hooks';
import { selectMarketOfferTokens, selectMarketSaleTokens } from '../../../../../state/market/hooks';
import {
  selectOfferAmount,
  selectOfferSaleAmount,
  selectOfferSaleToken,
  selectOfferToken,
  selectOfferType,
} from '../../../../../state/offer/hooks';
import {
  setOfferAmount,
  setOfferSaleAmount,
  setOfferSaleToken,
  setOfferToken,
  setOfferType,
} from '../../../../../state/offer/reducer';
import { selectUserBalances } from '../../../../../state/user/hooks';
import { BnToLocale, BnToNumberString } from '../../../../../utils/bigNumbers';

const SelectType = () => {
  const selectedType = useAppSelector(selectOfferType)
  const selectedToken = useAppSelector(selectOfferToken)
  const selectedSaleToken = useAppSelector(selectOfferSaleToken)
  const selectedSaleAmount = useAppSelector(selectOfferSaleAmount)
  const selectedAmount = useAppSelector(selectOfferAmount)
  const dispatch = useAppDispatch();

  const saleTokens = useAppSelector(selectMarketSaleTokens)
  const offerTokens = useAppSelector(selectMarketOfferTokens)
  const offerTokenTypes = offerTokens.map(t => t.type).filter((v, i, s) => s.indexOf(v) === i)
  const balances = useAppSelector(selectUserBalances);
  const [saleAmount, setSaleAmount] = useState(selectedSaleAmount)
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const balance = useMemo(() => {
    const t = balances[selectedToken.address]
    if (!t) {
      return BigNumber.from(0);
    }
    return selectedType === TokenType.ERC20Locked ? t.locked : t.balance;
  }, [selectedToken, selectedType, balances])

  const handleSelectType = (t: TokenType) => {
    dispatch(setOfferType(t))
  }

  const handleSelectToken = (e: SelectChangeEvent) => {
    const address = e.target.value;
    dispatch(setOfferToken(offerTokens.find(t => t.address === address) as any))
  }

  const handleSelectSaleToken = (e: SelectChangeEvent) => {
    const address = e.target.value;
    dispatch(setOfferSaleToken(saleTokens.find(t => t.address === address) as any))
  }

  const handleChangeSaleAmount = (e: ChangeEvent<HTMLInputElement>) => {
    let s = e.target.value;
    if (!s) {
      s = "";
    }
    if (s.startsWith('.')) {
      s = `0${s}`
    }
    s = s.replace(/[^.\d]/g, '');
    if (s.indexOf('.') !== s.lastIndexOf(".")) {
      console.log("nope", s.lastIndexOf('.'))
      s = s.slice(0, s.lastIndexOf('.'))
    }
    if (s.includes(".")) {
      const sp = s.split('.')
      if (sp[1].length > 3) {
        s = `${sp[0]}.${sp[1].slice(0,3)}`
      }
    }
    if (s.startsWith("0") && s.length > 1 && !s.startsWith("0.")) {
      s = s.slice(1, s.length)
    }
    setSaleAmount(s)
    dispatch(setOfferSaleAmount(s))
  }

  const handleChangAmountToSell = (e: ChangeEvent<HTMLInputElement>) => {
    let s = e.target.value;
    if (!s) {
      s = "";
    }
    if (s.startsWith('.')) {
      s = `0${s}`
    }
    s = s.replace(/[^.\d]/g, '');
    if (s.indexOf('.') !== s.lastIndexOf(".")) {
      s = s.slice(0, s.lastIndexOf('.'))
    }
    if (s.includes(".")) {
      const sp = s.split('.')
      if (sp[1].length > 3) {
        s = `${sp[0]}.${sp[1].slice(0,3)}`
      }
    }
    if (selectedToken.decimals === 0) {
      s = s.replace(".", "");
    }
    if (s.startsWith("0") && s.length > 1 && !s.startsWith("0.")) {
      s = s.slice(1, s.length)
    }
    dispatch(setOfferAmount(s))
  }

  useEffect(() => {
    if (selectedToken?.type !== selectedType || selectedToken.name === "") {
      const newToken = offerTokens.filter(o => o.type === selectedType)[0]

      if (newToken) {
        dispatch(setOfferToken(newToken))
      }
    }

  }, [selectedType, offerTokens, selectedToken]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedType === TokenType.ERC20Locked) {
      const locked = BnToNumberString(balances[selectedToken.address]?.locked || BigNumber.from(0)) || "0";
      if (selectedAmount !== locked) {
        dispatch(setOfferAmount(locked))
      }
    }
  }, [selectedToken, balances, selectedAmount, selectedType]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedSaleToken.name === "") {
      if (saleTokens.length > 0) {
        dispatch(setOfferSaleToken(saleTokens[0]))
      }
    }
  }, [selectedSaleToken, saleTokens]) // eslint-disable-line react-hooks/exhaustive-deps
  console.log(selectedAmount, Number(saleAmount))
  return (
    <Box sx={{ width: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', paddingY: 2 }}>
      <Typography variant="h6">Select a token type for your offer</Typography>
      <Stack spacing={2} direction="row" sx={{ marginY: 2 }}>
        {offerTokenTypes.map((t, i) => (
          <Button
            key={i}
            variant='contained'
            color={selectedType === t ? 'secondary' : 'primary'}
            sx={{ height: sm ? '150px' : '200px', width: sm ? '150px' : '200px', borderRadius: '16px' }}
            onClick={selectedType === t ? undefined : () => handleSelectType(t)}
          >
            <Stack>
              <Typography>
                {t === TokenType.ERC20Locked ? 'Locked Tokens' : t === TokenType.ERC20 ? "Tokens & Items" : "Special Items"}
              </Typography>
            </Stack>
          </Button>
        ))}
      </Stack>
      {selectedType === TokenType.ERC20Locked && (
        <Alert severity="warning" sx={{ maxWidth: sm ? 1 : 0.75 }}>
          You can only sell your entire locked balance. The {selectedToken.name} contract does not allow partial transfers
        </Alert>
      )}
      <Stack spacing={2} direction={sm ? 'column' : 'row'} sx={{ marginTop: 2, width: 1, justifyContent: 'center' }}>
        <FormControl sx={{ width: sm ? 1 : 0.5 }} color="secondary">
          <InputLabel id="sale-token-select-label">Choose {selectedType === TokenType.ERC20Locked ? 'Locked' : "Item"} Token</InputLabel>
          <Select
            labelId="sale-token-select-label"
            value={selectedToken.address || ''}
            label={`Choose ${selectedType === TokenType.ERC20Locked ? 'Locked' : "Item"} Token`}
            onChange={handleSelectToken}
          >
            {offerTokens.filter(o => o.type === selectedType).map((o, i) => (
              <MenuItem key={i} value={o.address}>{o.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          color="secondary"
          disabled={selectedToken.type === TokenType.ERC20Locked || balance.eq(0)}
        >
          <InputLabel htmlFor="outlined-adornment-amount2">
            {selectedType === TokenType.ERC20Locked ? 'Total Selling' : "Enter Amount To Sell"}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount2"
            value={selectedAmount}
            startAdornment={<InputAdornment position="start"></InputAdornment>}
            onChange={handleChangAmountToSell}
            endAdornment={<InputAdornment position="end">{selectedToken.symbol}</InputAdornment>}
            label={`${selectedType === TokenType.ERC20Locked ? 'Total Selling' : "Enter Amount To Sell"}`}
          />
          <FormHelperText>
            Balance: {BnToLocale(balance, selectedToken.decimals.toString())}
          </FormHelperText>
        </FormControl>
      </Stack>
      <Stack spacing={2} direction={sm ? 'column' : 'row'} sx={{ marginTop: 2, width: 1, justifyContent: 'center' }}>
        <FormControl sx={{ width: sm ? 1 : 0.5 }} color="secondary">
          <InputLabel id="token-select-label">Select Stablecoin</InputLabel>
          <Select
            labelId="token-select-label"
            value={selectedSaleToken.address || ''}
            label="Select Stablecoin"
            onChange={handleSelectSaleToken}
          >
            {saleTokens.map((o, i) => (
              <MenuItem key={i} value={o.address}>{o.symbol}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl color="secondary" fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">
            Set Price Per {selectedType === TokenType.ERC20Locked ? "Locked " : ""}{selectedToken.name}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={saleAmount}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            onChange={handleChangeSaleAmount}
            endAdornment={<InputAdornment position="end">{selectedSaleToken.symbol}</InputAdornment>}
            label={`Set Price Per ${selectedType === TokenType.ERC20Locked ? "Locked " : ""}${selectedToken.name}`}
          />
          <FormHelperText>
            Total: $ {(Number(selectedAmount) * Number(saleAmount)).toLocaleString()} {selectedSaleToken.symbol}
          </FormHelperText>
        </FormControl>
      </Stack>
    </Box>
  )
}

export default SelectType;