import Chains from './chains';
import { configureChains, createClient } from 'wagmi';
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from 'wagmi/providers/public';
import { getDefaultClient } from "connectkit";
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

export const chainList = [
  Chains.klaytn,
  Chains.dfk,
];

const { chains, provider } = configureChains(chainList, [
  jsonRpcProvider({
    rpc: (chain) => {
      if (!Object.values(Chains).map(c => c.id).includes(chain.id)) return null;
      return { http: chain.rpcUrls.default.http[0] };
    },
    weight: 1,
  }),
  publicProvider({ weight: 2 }),
])

export const client = createClient(getDefaultClient({
  autoConnect: true,
  appName: 'EvoVerses',
  chains,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: false,
      } as any,
    }),
  ],
  provider,
}));

const ChainData = { client, chainList }
export default ChainData;