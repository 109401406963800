import { GitHub, Home, QuestionMark, Telegram, Twitter } from '@mui/icons-material';
import { Box, Divider, Drawer, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { FC } from 'react';
import { Link as NavLink } from 'react-router-dom';
import { ReactComponent as Discord } from '../../assets/svg/discord-mark-white.svg';

interface Props {
  open: boolean
  onClose: () => void;
}
const AppDrawer: FC<Props> = ({ open, onClose }) => {
  const internalLinks = [
    { name: "Home", to: "/", icon: <Home /> },
    { name: "FAQ", to: "faq", icon: <QuestionMark /> },
  ]
  const externalLinks = [
    { name: "Twitter", href: "https://twitter.com/FoxswapFi", icon: <Twitter /> },
    { name: "Discord", href: "https://discord.gg/VwaRP8dWUZ", icon: <Discord width="24px" height="24px" /> },
    { name: "Telegram", href: "https://t.me/farmersonlyfi", icon: <Telegram /> },
    { name: "GitHub", href: "https://github.com/FarmersOnlyFi/fox-market-contracts", icon: <GitHub /> }
  ]
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{ width: 200 }}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
      >
        <List>
          {internalLinks.map(({name, to, icon}, i) => (
            <ListItem key={i} disablePadding>
              <ListItemButton component={NavLink} to={to}>
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {externalLinks.map(({name, href, icon}, i) => (
            <ListItem key={i} disablePadding>
              <ListItemButton component={Link} href={href} target="_blank">
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Box>
    </Drawer>

  )
}

export default AppDrawer;