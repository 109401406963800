import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';
import Slide from '../../../components/Transitions/Slide';
import { getSigned, useFoxMarket } from '../../../constants/contracts';
import { useCooldown, useWallet } from '../../../hooks/account';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
  selectOfferStep,

  selectOfferCancelEscrow,
} from '../../../state/offer/hooks';
import { setOfferCancelEscrow, setOfferStep } from '../../../state/offer/reducer';

interface Props {
  open: boolean
  onClose: () => void
}

const CancelOfferDialog: FC<Props> = ({ open, onClose }) => {
  const activeStep = useAppSelector(selectOfferStep)
  const dispatch = useAppDispatch()
  const { signer } = useWallet();
  const cancelOfferEscrow = useAppSelector(selectOfferCancelEscrow)
  const { enqueueSnackbar } = useSnackbar()

  const cooldown = useCooldown();


  const contract = useFoxMarket();
  const handleCancel = async () => {
    const c = getSigned(contract, signer as any);
    try {
      const tx = await c.cancelOffer(cancelOfferEscrow)
      await tx.wait();
      enqueueSnackbar("Cancelled offer", { variant: "success" })
    } catch (e: any) {
      console.log(e)
      enqueueSnackbar(e.reason, { variant: "error" })
    }
  }
  const handleBack = () => {
    dispatch(setOfferStep(activeStep - 1))
  }
  const handleClose = () => {
    dispatch(setOfferCancelEscrow(""))
  }
  return (
    <Dialog
      open={cancelOfferEscrow !== ""}
      TransitionComponent={Slide}
      keepMounted
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '16px'
        }
      }}
    >
      <DialogTitle align="center">Cancel Offer</DialogTitle>
      <Box sx={{ position: 'absolute', top: 12, right: 12 }} onClick={handleClose}>
        <Close style={{ width: "30px", height: '30px' }} />
      </Box>
      <DialogContent sx={{ paddingY: 0, paddingBottom: 2 }}>
        <Typography sx={{ textAlign: "center" }}>Are you sure?</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', paddingX: 4, paddingTop: 0, paddingBottom: 3 }}>
        {activeStep !== 0 && (
          <Button variant="contained" color="secondary" onClick={handleBack}>Back</Button>
        )}
        <Button
          disabled={cooldown.onCooldown}
          variant="contained"
          sx={{ backgroundColor: "red" }}
          onClick={handleCancel}
        >
          {cooldown.onCooldown ? "On cooldown" : "Cancel Offer"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelOfferDialog;