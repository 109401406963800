import { providers } from "ethers";
import { useAccount, useNetwork, useProvider, useSigner } from 'wagmi';
import { DEV_MODE } from '../constants';
import chains from '../constants/wagmi/chains';
import { useAppSelector } from '../state/hooks';
import { selectOfferToken } from '../state/offer/hooks';
import { selectUserBalances } from '../state/user/hooks';

export const useWallet = () => {
  const { chain } = useNetwork();
  const { address } = useAccount();
  const provider: providers.Web3Provider = useProvider();
  const { data } = useSigner();

  const wallet = (DEV_MODE ? '' : '') || address || '';

  return {
    chain: chain || chains.klaytn,
    chainId: chain?.id,
    account: wallet,
    provider,
    signer: data,
  };
}

export const useCooldown = () => {
  const balances = useAppSelector(selectUserBalances);
  const selectedToken = useAppSelector(selectOfferToken);
  const now = Math.floor(Date.now() / 1000);
  const completeAt = (balances[selectedToken?.address]?.cooldown || 0) + (86400 * 3)
  const onCooldown = completeAt > now
  const secondsLeft = now - completeAt;
  return {
    completeAt,
    onCooldown,
    secondsLeft
  }
}

