import { DEV_MODE } from '../constants';
import { configureStore } from '@reduxjs/toolkit';
import applicationReducer from "./application/reducer";
import marketReducer from './market/reducer';
import offerReducer from "./offer/reducer";
import userReducer from "./user/reducer";

const store = configureStore({
  devTools: DEV_MODE,
  reducer: {
    application: applicationReducer,
    offer: offerReducer,
    market: marketReducer,
    user: userReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
})

export default store;


export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
