import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { addresses, approveERC20 } from '../../../../../constants/contracts';
import { useWallet } from '../../../../../hooks/account';
import { useAppDispatch, useAppSelector } from '../../../../../state/hooks';
import { selectOfferStep, selectOfferToken } from '../../../../../state/offer/hooks';
import { setOfferStep, setStep2Complete } from '../../../../../state/offer/reducer';
import { selectUserBalances } from '../../../../../state/user/hooks';



const ApproveToken = () => {
  const selectedToken = useAppSelector(selectOfferToken)
  const { chain, signer } = useWallet();
  const { enqueueSnackbar } = useSnackbar();
  const balances = useAppSelector(selectUserBalances);
  const currentStep = useAppSelector(selectOfferStep)
  const dispatch = useAppDispatch()
  const approve = async () => {
    try {
      await approveERC20(selectedToken.address, addresses[chain.id], signer as any);

      enqueueSnackbar("Approved", { variant: 'success' })
      dispatch(setStep2Complete(true))
      dispatch(setOfferStep(2))
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.reason, { variant: 'error' })
    }
  }

  useEffect(() => {
    if (currentStep === 1 && balances[selectedToken.address].approved) {
      dispatch(setStep2Complete(true))
      dispatch(setOfferStep(2))
    }
  }, [balances, selectedToken, currentStep])  // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box sx={{ width: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', paddingY: 2 }}>
      <Typography variant="h6">Approve Fox Market to sell your {selectedToken.name}</Typography>
      <Typography sx={{ textAlign: 'center' }}>(summary available on the next step)</Typography>
      <Button
        variant='contained'
        color="secondary"
        sx={{ height: '200px', width: '200px', borderRadius: '16px', marginY: 2 }}
        onClick={approve}
      >
        <Stack>
          <Typography>Approve</Typography>
          <Typography>{selectedToken.name}</Typography>
        </Stack>

      </Button>
    </Box>
  )
}

export default ApproveToken;