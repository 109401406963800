import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#212429',
    },
    secondary: {
      main: '#8c40f5',
    },
  },
  typography: {
    fontFamily: "Noto Sans"
  }
})

export default theme;