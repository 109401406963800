import { AppState } from '../index';

export const selectOfferType = (state: AppState) => state.offer.type;
export const selectOfferToken = (state: AppState) => state.offer.token;
export const selectOfferSaleToken = (state: AppState) => state.offer.saleToken;
export const selectOfferSaleAmount = (state: AppState) => state.offer.saleAmount;
export const selectOfferStep = (state: AppState) => state.offer.step;
export const selectOfferAmount = (state: AppState) => state.offer.amount;
export const selectOfferStep2Complete = (state: AppState) => state.offer.step2Complete;
export const selectOfferCancelEscrow = (state: AppState) => state.offer.cancelEscrow;
export const selectOfferCreate = (state: AppState) => state.offer.createOffer;