import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserToken } from '../../models/tokens';
import { UserState } from './types';

const initialState: UserState = {
  lastUpdated: 0,
  balances: {}
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserBalances: (state, action: PayloadAction<{ [address: string]: UserToken }>) => {
      state.balances = action.payload
    },
    setUserLastUpdated: (state, action: PayloadAction<number>) => {
      state.lastUpdated = action.payload
    },
  }
})

export const {
  setUserBalances,
  setUserLastUpdated
} = userSlice.actions;

export default userSlice.reducer;