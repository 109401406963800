/** Signature of a logging function */
import { DEV_MODE } from '../constants';

interface LogFunction {
  (message?: any, ...optionalParams: any[]): void;
}

/** Basic logger interface */
interface Logger {
  info: LogFunction;
  log: LogFunction;
  warn: LogFunction;
  error: LogFunction;
  debug: LogFunction;
}

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
  name?: string
  constructor(name?: string) {
    this.name = name;
  }

  private addName = (message?: any): any => {
    if (this.name) {
      return `%c ${this.name} > ${message}`;
    }
    return message;
  }

  log = (message?: any, ...optionalParams: any[]): void => {
    console.log(this.addName(message), ...optionalParams);
  }

  info = this.log

  warn = (message?: any, ...optionalParams: any[]): void => {
    console.warn(this.addName(message), ...optionalParams);
  }

  error = (message?: any, ...optionalParams: any[]): void => {
    if (typeof message === 'string') {
      console.log(this.addName(message) , `background: red;`, ...optionalParams);
      return;
    } else {
      console.log(this.addName(""), `background: red;`, message, ...optionalParams);
    }
  }

  debug = (message?: any, ...optionalParams: any[]): void => {
    if (DEV_MODE) {
      if (typeof message === 'string') {
        console.log(this.addName(message) , `background: blue;`, ...optionalParams);
        return;
      } else {
        console.log(this.addName(""), `background: blue;`, message, ...optionalParams);
      }
    }
  }
}

const GetLogger = (name?: string): ConsoleLogger => new ConsoleLogger(name)

export default GetLogger;
