import { utils, BigNumber } from 'ethers';

export function BnToLocale(val: BigNumber, units = "ether") : string {
  const s = utils.formatUnits(val, units).split('.')
  if (s.length === 1) {
    return Number(s[0]).toLocaleString()
  }
  return Number(`${s[0]}.${s[1].substring(0, 4)}`).toLocaleString()
}

export function BnToNumberString(val: BigNumber, units = "ether") : string {
  const s = utils.formatUnits(val, units).split('.')
  if (s.length === 1) {
    return Number(s[0]).toString()
  }
  return Number(`${s[0]}.${s[1].substring(0, 4)}`).toString()
}
