import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from './types';

const initialState: ApplicationState = {
  loading: false
}

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setApplicationLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  }
})

export const {
  setApplicationLoading,
} = applicationSlice.actions;

export default applicationSlice.reducer;