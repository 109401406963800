import { BigNumber, constants } from 'ethers';
import { useEffect } from 'react';
import { addresses, getERC20 } from '../../constants/contracts';
import { networkProviders } from '../../constants/wagmi/baseProviders';
import { useWallet } from '../../hooks/account';
import { TokenType } from '../../models/tokens';
import GetLogger from '../../utils/Logger';
import { useAppDispatch, useAppSelector } from '../hooks';
import { selectMarketOfferTokens, selectMarketSaleTokens } from '../market/hooks';
import { selectUserBalances, selectUserLastUpdated } from './hooks';
import { setUserBalances, setUserLastUpdated } from './reducer';

const UserUpdater = (): null => {
  const log = GetLogger('User Updater');
  const { chain, account } = useWallet();
  const provider = networkProviders[chain.id];
  const dispatch = useAppDispatch();
  const approvedOfferTokens = useAppSelector(selectMarketOfferTokens);
  const approvedSaleTokens = useAppSelector(selectMarketSaleTokens);
  const lastUpdated = useAppSelector(selectUserLastUpdated);
  const userBalances = useAppSelector(selectUserBalances);

  useEffect(() => {
    const now = Date.now();
    if ((now - lastUpdated) < 60000) {
      log.debug("Skipping user update. Has only been", Math.round((now - lastUpdated) / 1000), "seconds")
      return;
    }
    const get = async () => {
      if (approvedOfferTokens.length === 0 || approvedSaleTokens.length === 0) {
        return;
      }
      if (!account) {
        return;
      }
      const userTokens = { ...userBalances };
      const offerTokenDataCalls = [];
      try {
        let update = false;
        for (const offerToken of approvedOfferTokens) {
          offerTokenDataCalls.push(getERC20(offerToken.address).balanceOf(account))
          offerTokenDataCalls.push(getERC20(offerToken.address).allowance(account, addresses[chain.id]))
          if (offerToken.type === TokenType.ERC20Locked) {
            offerTokenDataCalls.push(getERC20(offerToken.address).lockOf(account))
          } else {
            offerTokenDataCalls.push(getERC20(offerToken.address).balanceOf(account))
          }
        }
        const offerTokenData = await provider.all(offerTokenDataCalls);
        for (let i = 0; i < approvedOfferTokens.length; i++) {
          const t = approvedOfferTokens[i];
          const balance = offerTokenData[i * approvedOfferTokens.length + i];
          const approved = offerTokenData[i * approvedOfferTokens.length + i + 1].gt(constants.MaxUint256.div(2));
          const locked = offerTokenData[i * approvedOfferTokens.length + i + 2];
          let cooldown = 0;
          if (['JEWEL', 'JADE', 'CRYSTAL'].includes(t.symbol)) {
            const [cooldownRaw] = await provider.all([getERC20(t.address).transferAllTracker(account)]);
            cooldown = cooldownRaw.toNumber();
          }
          if (!(t.address in userTokens)) {
            userTokens[t.address] = { ...t, balance, approved, locked, cooldown }
            update = true;
          } else {
            if (!userTokens[t.address].balance.eq(balance)) {
              userTokens[t.address] = { ...userTokens[t.address], balance };
              update = true;
            }
            if (userTokens[t.address].approved !== approved) {
              userTokens[t.address] = { ...userTokens[t.address], approved };
              update = true;
            }
            if (userTokens[t.address].locked !== locked) {
              userTokens[t.address] = { ...userTokens[t.address], locked };
              update = true;
            }
          }
        }
        const saleTokenDataCalls = [];
        for (const saleToken of approvedSaleTokens) {
          const contract = getERC20(saleToken.address);
          saleTokenDataCalls.push(contract.balanceOf(account))
          saleTokenDataCalls.push(contract.allowance(account, addresses[chain.id]))
        }
        const saleTokenData = await provider.all(saleTokenDataCalls);
        for (let i = 0; i < approvedSaleTokens.length; i++) {
          const t = approvedSaleTokens[i];
          const balance = saleTokenData[i * approvedSaleTokens.length];
          const approved = saleTokenData[i * approvedSaleTokens.length + 1].gt(constants.MaxUint256.div(2));
          if (!(t.address in userTokens)) {
            userTokens[t.address] = { ...t, balance, approved, locked: BigNumber.from(0), cooldown: 0 }
            update = true;
          } else {
            if (!userTokens[t.address].balance.eq(balance)) {
              userTokens[t.address] = { ...userTokens[t.address], balance };
              update = true;
            }
            if (userTokens[t.address].approved !== approved) {
              userTokens[t.address] = { ...userTokens[t.address], approved };
              update = true;
            }
          }
        }
        log.debug("User Tokens", userTokens)
        if (update) {
          dispatch(setUserBalances(userTokens))
        }
        dispatch(setUserLastUpdated(Date.now()))
      } catch (e) {
        log.error('Error querying for data', e);
      }
    };
    get();
    const interval = setInterval(() => {
      get();
    }, 120000);

    return () => {
      clearInterval(interval);
    };
  }, [account, userBalances, approvedOfferTokens, approvedSaleTokens, chain.id, provider, lastUpdated]); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};

export default UserUpdater;
