import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { utils } from 'ethers';
import { useSnackbar } from 'notistack';
import React from 'react';
import { getSigned, useFoxMarket } from '../../../../../constants/contracts';
import { useCooldown, useWallet } from '../../../../../hooks/account';
import { TokenType } from '../../../../../models/tokens';
import { useAppDispatch, useAppSelector } from '../../../../../state/hooks';
import {
  selectOfferAmount,
  selectOfferSaleAmount,
  selectOfferSaleToken,
  selectOfferToken,
  selectOfferType,
} from '../../../../../state/offer/hooks';
import { setOfferStep, setStep2Complete } from '../../../../../state/offer/reducer';

const CreateOffer = () => {
  const { signer } = useWallet();
  const selectedToken = useAppSelector(selectOfferToken);
  const selectedTokenAmount = useAppSelector(selectOfferAmount)
  const selectedSaleToken = useAppSelector(selectOfferSaleToken)
  const selectedSaleAmount = useAppSelector(selectOfferSaleAmount)
  const selectedType = useAppSelector(selectOfferType);
  const { enqueueSnackbar } = useSnackbar();
  const contract = useFoxMarket();
  const dispatch = useAppDispatch();

  const cooldown = useCooldown();
  const create = async () => {
    if (!signer) {
      return;
    }
    const c = getSigned(contract, signer)
    try {
      if (selectedType === TokenType.ERC20Locked) {
        const totalCost = Number(selectedTokenAmount) * Number(selectedSaleAmount)
        const totalCostString = totalCost.toString();
        const fixedTotalCost = totalCostString.includes(".") ? totalCostString : `${totalCostString}.0`
        const total = utils.parseUnits(fixedTotalCost, selectedSaleToken.decimals.toString())
        const tx = await c.createERC20LockedOffer(selectedToken.address, selectedSaleToken.address, total);
        await tx.wait();
        enqueueSnackbar("Created contract", { variant: 'success'})
        dispatch(setStep2Complete(true))
        dispatch(setOfferStep(2))
      } else if (selectedType === TokenType.ERC20) {
        const totalCost = Number(selectedTokenAmount) * Number(selectedSaleAmount)
        const totalCostString = totalCost.toString();
        const fixedTotalCost = totalCostString.includes(".") ? totalCostString : `${totalCostString}.0`
        const total = utils.parseUnits(fixedTotalCost, selectedSaleToken.decimals.toString())
        const selectedAmountFormatted = selectedTokenAmount.includes('.') ? selectedTokenAmount : `${selectedTokenAmount}.0`
        const selectedTotal = utils.parseUnits(selectedAmountFormatted, selectedToken.decimals.toString())
        console.log(selectedToken.address, selectedToken.address, selectedSaleToken.address, total, selectedTotal)
        const tx = await c.createERC20Offer(selectedToken.address, selectedSaleToken.address, selectedTotal, total);
        await tx.wait();
        enqueueSnackbar("Created offer!", { variant: 'success'})
      }
    } catch (e: any) {
      console.log(e)
      enqueueSnackbar(e.reason, { variant: 'error'})
    }

    // approveERC20(selectedToken.address)
  }

  return (
    <Box sx={{ width: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', paddingY: 2 }}>
      <Stack>
        <Typography variant="h6" sx={{ textAlign: 'center' }}>Your Offer Details</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Selling</TableCell>
              <TableCell align="center">For</TableCell>
              <TableCell align="center">At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                {Number(selectedTokenAmount).toLocaleString()} {selectedToken.symbol}
              </TableCell>
              <TableCell align="center">
                {(Number(selectedSaleAmount) * Number(selectedTokenAmount)).toLocaleString()} {selectedSaleToken.symbol}
              </TableCell>
              <TableCell align="center">
                {Number(selectedSaleAmount).toLocaleString()} each
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
      <Button
        variant='contained'
        color="secondary"
        sx={{ borderRadius: '16px', marginY: 2 }}
        onClick={create}
        disabled={cooldown.onCooldown}
      >
        <Typography>{cooldown.onCooldown ? 'On cooldown' : "Create Offer Contract"}</Typography>
      </Button>
    </Box>
  )
}

export default CreateOffer;