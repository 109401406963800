import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ConnectKitButton } from 'connectkit';
import React, { useMemo, useState } from 'react';
import MenuIcon from "@mui/icons-material/Menu"
import Logo from '../../assets/images/old-logo.jpg';
import Chains from '../../constants/wagmi/chains';
import { useWallet } from '../../hooks/account';
import { useAppSelector } from '../../state/hooks';
import { selectUserBalances } from '../../state/user/hooks';
import { Link as NavLink } from 'react-router-dom';
import AppDrawer from './AppDrawer';

const NavBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { chainId } = useWallet()
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const balances = useAppSelector(selectUserBalances);
  const cooldown = useMemo(() => {
    let cd;
    if (chainId === Chains.dfk.id) {
      cd = Object.values(balances || {})?.find(b => b.symbol === 'CRYSTAL')?.cooldown;
    } else {
      cd = Object.values(balances || {})?.find(b => b.symbol === 'JADE')?.cooldown;
    }
    return cd || 0;
  }, [balances, chainId])

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const timeFormatted = () => {
    let str = ""
    const totalSeconds = (cooldown + (86400 * 3)) - Math.floor(Date.now() / 1000)
    const hours = Math.floor(totalSeconds / 3600)
    if (hours > 0) {
      str = `${hours}:`
    }
    const minutes = Math.floor((totalSeconds - (hours * 3600)) / 60)
    console.log(hours, minutes)
    str = `${str}${minutes.toString().padStart(2, "0")}:`
    const seconds = Math.floor((totalSeconds - (hours * 3600) - (minutes * 60)) / 60)
    console.log(hours, minutes, seconds)
    str = `${str}${seconds.toString().padStart(2, "0")}`
    return str;
  }

  const links = [
    { name: "Twitter", href: "https://twitter.com/FoxswapFi" },
    { name: "Discord", href: "https://discord.gg/VwaRP8dWUZ" },
    { name: "Telegram", href: "https://t.me/farmersonlyfi" },
    { name: "GitHub", href: "https://github.com/FarmersOnlyFi/fox-market-contracts" }
  ]
  return (
    <AppBar component="nav">
      <Toolbar>
        {!md ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
        ) : (
          <Box component={NavLink} color="white" to="/">
            <Avatar sx={{ width: '32px', height: '32px' }} src={Logo} />
          </Box>
        )}
        <Box sx={{ minWidth: '120px', ...(md ? {} : { display: 'flex', width: 1 }) }}>
          <Box
            component={NavLink}
            to="/"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 1,
              textDecoration: 'none'
            }}
          >
            <Typography variant="h6" color="white">
              Fox Market
            </Typography>
          </Box>
        </Box>
        {md && (
          <Stack direction="row" sx={{ display: 'flex', width: 1, marginLeft: 1 }}>
            {links.map((l, i) => (
              <Button key={i} component={Link} href={l.href} target="_blank" sx={{ textTransform: 'none', height: 1 }}>
                <Typography color="white" >{l.name}</Typography>
              </Button>
            ))}
            <Button component={NavLink} to="faq" sx={{ textTransform: 'none', height: 1 }}>
              <Typography color="white" >FAQ</Typography>
            </Button>
          </Stack>
        )}
        {cooldown > 0 && (
          <Typography sx={{ marginRight: 2 }}>
            Cooldown Remaining {timeFormatted()}
          </Typography>
        )}
        <ConnectKitButton />
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenu}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
        </Menu>
      </Toolbar>
      <AppDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
    </AppBar>
  )
}

export default NavBar;
