import { Box, CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Outlet } from 'react-router-dom';
import AppBar from '../components/Navigation/AppBar';
import "./App.css"
const App = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar />
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ paddingTop: 8, width: 1, display: 'flex', justifyContent: 'center' }}>
          <Outlet />
        </Box>
      </SnackbarProvider>
    </Box>
  )
}

export default App;