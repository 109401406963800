import React, { Fragment } from 'react';
import MarketUpdater from './market/updater';
import UserUpdater from './user/updater';

const Updaters = () => {
  return (
    <Fragment>
      <MarketUpdater />
      <UserUpdater />
    </Fragment>
  )
}

export default Updaters;