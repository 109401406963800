import { providers } from 'ethers';
import Chains from './chains';
import { Provider, setMulticallAddress } from 'ethers-multicall';
import { chainList } from './index';
import { NetworkProviders } from './types';

export const networkBaseProviders = chainList.reduce((o, chain) => {
  o[chain.id] = new providers.StaticJsonRpcProvider(chain.rpcUrls.default.http[0])
  return o;
}, {} as { [chainId: number]: providers.StaticJsonRpcProvider})

export const networkProviders = chainList.reduce((o, chain) => {
  setMulticallAddress(Chains.klaytn.id, "0xd11dfc2ab34abd3e1abfba80b99aefbd6255c4b8");
  //setMulticallAddress(Chains.dfk.id, "");
  o[chain.id] = new Provider(new providers.JsonRpcProvider(chain.rpcUrls.default.http[0]), chain.id)
  return o;
}, {} as NetworkProviders)