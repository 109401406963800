import { Contract as MCContract } from 'ethers-multicall';
import { constants, Contract, ethers, Signer } from 'ethers';

import FoxMarketArtifact from '../../artifacts/FoxMarket.json'
import DFKToken from '../../artifacts/DFKToken.json'
import EternalStoryToken from '../../artifacts/DFKToken.json'
import { useWallet } from '../../hooks/account';
import { networkProviders } from '../wagmi/baseProviders';
import Chains, { EChain } from '../wagmi/chains';

export const ABIs = {
  ERC20: DFKToken,
  ERC1155: EternalStoryToken,
  FoxMarket: FoxMarketArtifact.abi
}

export const addresses = {
  [Chains.klaytn.id]: "0xa0830985871c69c5F4788A6f95B3a238e2Bb8afC",
  [Chains.dfk.id]: "0xa0830985871c69c5F4788A6f95B3a238e2Bb8afC"
}

export const getSigned = (contract: MCContract, signer: ethers.Signer) =>
  new Contract(contract.address, contract.abi, signer);

export const getERC20 = (address: string): MCContract => new MCContract(address, ABIs.ERC20);

export const GetERC1155 = (address: string): MCContract => new MCContract(address, ABIs.ERC1155);

export const approveERC20 = async (
  token: string,
  spender: string,
  signer: Signer,
): Promise<void> => {
  const contract = getSigned(getERC20(token), signer);
  const tx = await contract.approve(spender, constants.MaxUint256);
  await tx.wait(1);
}

export const approveERC20For = async (token: string, address: string, signer: Signer) => {
  const contract = getSigned(getERC20(token), signer);
  const tx = await contract.approve(address, constants.MaxUint256);
  await tx.wait();
}

export const checkERC20Approved = async (token: string, owner: string, address: string, chain: EChain): Promise<boolean> => {
  const provider = networkProviders[chain.id];
  const contract = getERC20(token);
  const [ amount ] = await provider.all([ contract.allowance(owner, address) ]);
  return amount.gt(constants.MaxUint256.div(2))
}

export const useFoxMarket = (): MCContract => {
  const { chain } = useWallet();
  return new MCContract(addresses[chain.id], ABIs.FoxMarket);
}
