import { Box, Button, LinearProgress, Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { utils } from 'ethers';
import React from 'react';
import { useWallet } from '../../../../hooks/account';
import { TokenType } from '../../../../models/tokens';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import { selectMarketOffers, selectMarketOfferTokens, selectMarketSaleTokens } from '../../../../state/market/hooks';
import { setOfferCancelEscrow, setOfferCreate } from '../../../../state/offer/reducer';

const CustomToolbar = () => {
  const { account } = useWallet();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'))
  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between', paddingX: 2, paddingTop: 2 }}>
      <Stack direction="row" spacing={2}>
        <GridToolbarColumnsButton variant="contained" color="secondary" />
        <GridToolbarFilterButton componentsProps={{ button: { variant: "contained", color: "secondary" } }}  />
        {!xs && <GridToolbarDensitySelector variant="contained" color="secondary" />}

      </Stack>

      {/* <GridToolbarExport variant="contained" color="secondary" /> */}
      <Button
        variant="contained"
        color="success"
        size="small"
        onClick={() => dispatch(setOfferCreate(true))}
        disabled={!account}
      >
        {account ? "Create Offer" : "Not Connected"}
      </Button>
    </GridToolbarContainer>
  );
}

const Grid = () => {
  const offers = useAppSelector(selectMarketOffers);
  const offerTokens = useAppSelector(selectMarketOfferTokens)
  const saleTokens = useAppSelector(selectMarketSaleTokens)
  const { account } = useWallet();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useAppDispatch();
  const handleBuy = () => {

  }

  const handleCancel = async (escrow: string) => {
    dispatch(setOfferCancelEscrow(escrow));
  }

  const common: GridColDef = { field: "common", align: "center", headerAlign: "center", flex: 1 }
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', hide: true, sortable: false, filterable: false },
    {
      ...common,
      field: 'escrow',
      headerName: "Contract",
      hide: true
    },
    {
      ...common,
      field: 'tokenType',
      headerName: "Type",
      minWidth: 70,
    },
    {
      ...common,
      hide: sm,
      field: 'seller',
      headerName: "Seller",
      renderCell: params =>
        params.value.slice(0, 6) + "..." + params.value.slice(params.value.length - 4, params.value.length)
    },
    {
      ...common,
      field: 'offerToken',
      headerName: "Token",
      minWidth: 100,
    },
    {
      ...common,
      field: 'offerTokenId',
      headerName: "Token ID",
      hide: true,
      filterable: false
    },
    {
      ...common,
      field: 'offerAmount',
      headerName: "Selling",
      type: "number",
      minWidth: 150,
      renderCell: params => {
        return params.value ? params.value  + " " + params.row.offerToken : '-'
      }
    },
    {
      ...common,
      field: 'saleToken',
      headerName: 'Purchase Token',
      minWidth: 150,
      hide: true
    },
    {
      ...common,
      field: 'saleAmount',
      headerName: 'Total Cost',
      minWidth: 150,
      type: "number"
    },
    {
      ...common,
      field: "cooldown",
      headerName: 'Cooldown',
      hide: true,
      type: "dateTime",
      renderCell: params => {
        return params.value > 0 ? params.value : '-'
      }
    },
    {
      ...common,
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 1,
      renderCell: params => {
        return params.row.cooldown > 0 ? "On Cooldown" : (
          <Stack spacing={1} direction="row">
            {params.row.seller !== account && (
              <Button variant="contained" color="secondary" onClick={handleBuy} disabled={!account}>
                Buy
              </Button>
            )}

            {params.row.seller === account && (
              <Button variant="contained" color="secondary" onClick={() => handleCancel(params.row.escrow)}>
                Cancel
              </Button>
            )}
          </Stack>
        )
      }
    }
  ]
  const rows = offers.map((o, i) => ({
    ...o,
    id: i,
    tokenType: o.type === TokenType.ERC20Locked ? 'Locked' : o.type === TokenType.ERC20 ? "Token" : "Item",
    offerToken: offerTokens.find(ot => ot.address === o.offerToken)?.symbol,
    offerTokenId: o.type === TokenType.ERC1155 ? o.offerTokenId : "-",
    offerAmount: Number(utils.formatUnits(o.offerAmount, offerTokens.find(st => st.address === o.offerToken)?.decimals.toString())).toLocaleString(),
    saleToken: saleTokens.find(st => st.address === o.saleToken)?.symbol,
    saleAmount: Number(utils.formatUnits(o.saleAmount, saleTokens.find(st => st.address === o.saleToken)?.decimals.toString())).toLocaleString() + " " + saleTokens.find(st => st.address === o.saleToken)?.symbol,
  }));
  return (
    <Box sx={{ width: '100%' }}>

        <DataGrid
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
          }}
          componentsProps={{
            loadingOverlay: {
              color: 'secondary'
            }
          }}
          initialState={{
            filter: {
              filterModel: {
                items: [{ columnField: 'offerAmount', operatorValue: '>', value: '0' }]
              }
            }
          }}
          loading={rows.length === 0}
          autoHeight
          columns={columns}
          rows={rows}
          disableSelectionOnClick
          disableColumnMenu
        />

    </Box>
  )
}

export default Grid;
