import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Token, TokenType } from '../../models/tokens';
import { OfferState } from './types';

const initialState: OfferState = {
  type: TokenType.ERC20Locked,
  token: {
    name: "",
    symbol: "",
    address: "",
    decimals: 0,
    type: TokenType.ERC20Locked
  },
  step: 0,
  amount: "0",
  saleAmount: "0",
  saleToken: {
    name: "",
    symbol: "",
    address: "",
    decimals: 0,
    type: TokenType.ERC20
  },
  step2Complete: false,
  cancelEscrow: "",
  createOffer: false
}

export const applicationSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    setOfferType: (state, action: PayloadAction<TokenType>) => {
      state.type = action.payload
      state.amount = "0"
    },
    setOfferToken: (state, action: PayloadAction<Token>) => {
      state.token = action.payload
    },
    setOfferStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload
    },
    setOfferAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload
    },
    setOfferSaleToken: (state, action: PayloadAction<Token>) => {
      state.saleToken = action.payload
    },
    setOfferSaleAmount: (state, action: PayloadAction<string>) => {
      state.saleAmount = action.payload
    },
    setStep2Complete: (state, action: PayloadAction<boolean>) => {
      state.step2Complete = action.payload
    },
    setOfferCancelEscrow: (state, action: PayloadAction<string>) => {
      state.cancelEscrow = action.payload
    },
    setOfferCreate: (state, action: PayloadAction<boolean>) => {
      state.createOffer = action.payload
    },
  }
})

export const {
  setOfferType,
  setOfferToken,
  setOfferStep,
  setOfferAmount,
  setOfferSaleToken,
  setOfferSaleAmount,
  setStep2Complete,
  setOfferCancelEscrow,
  setOfferCreate
} = applicationSlice.actions;

export default applicationSlice.reducer;