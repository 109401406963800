import { useEffect } from 'react';
import { getERC20, useFoxMarket } from '../../constants/contracts';
import { networkProviders } from '../../constants/wagmi/baseProviders';
import { useWallet } from '../../hooks/account';
import { IOffer } from '../../models/market';
import { Token, TokenType } from '../../models/tokens';
import GetLogger from '../../utils/Logger';
import { useAppDispatch, useAppSelector } from '../hooks';
import { selectMarketOfferTokens, selectMarketSaleTokens } from './hooks';
import { setMarketOffers, setMarketOfferTokens, setMarketSaleTokens } from './reducer';

const MarketUpdater = (): null => {
  const log = GetLogger('Market Updater');
  const { chain } = useWallet();
  const provider = networkProviders[chain.id];
  const dispatch = useAppDispatch();
  const contract = useFoxMarket();

  const approvedOfferTokens = useAppSelector(selectMarketOfferTokens)
  const approvedSaleTokens = useAppSelector(selectMarketSaleTokens)
  useEffect(() => {
    const get = async () => {
      try {
        const [[offerTokenAddresses, saleTokenAddresses]] = await provider.all([contract.supportedTokens()]);
        const offerTokenDataCalls = [];
        for (const address of offerTokenAddresses) {
          const c = getERC20(address);
          offerTokenDataCalls.push(c.name())
          offerTokenDataCalls.push(c.symbol())
          offerTokenDataCalls.push(c.decimals())
        }
        const offerTokens: Token[] = [];
        const offerTokenData = await provider.all(offerTokenDataCalls);
        for (let i = 0; i < offerTokenAddresses.length; i++) {

          const t = {
            name: offerTokenData[i * offerTokenAddresses.length + i],
            symbol: offerTokenData[i * offerTokenAddresses.length + i + 1],
            decimals: offerTokenData[i * offerTokenAddresses.length + i + 2],
            address: offerTokenAddresses[i],
            type: TokenType.ERC20Locked
          }

          if (t.decimals < 18) {
            t.type = TokenType.ERC20
          }
          offerTokens.push(t)
        }
        if (approvedOfferTokens.length !== offerTokens.length) {
          dispatch(setMarketOfferTokens(offerTokens))
        }
        const saleTokenDataCalls = [];
        for (const address of saleTokenAddresses) {
          const c = getERC20(address);
          saleTokenDataCalls.push(c.name())
          saleTokenDataCalls.push(c.symbol())
          saleTokenDataCalls.push(c.decimals())
        }
        const saleTokens: Token[] = [];
        const saleTokenData = await provider.all(saleTokenDataCalls);
        for (let i = 0; i < saleTokenAddresses.length; i++) {
          saleTokens.push({
            name: saleTokenData[i * saleTokenAddresses.length + i],
            symbol: saleTokenData[i * saleTokenAddresses.length + i + 1],
            decimals: saleTokenData[i * saleTokenAddresses.length + i + 2],
            address: saleTokenAddresses[i],
            type: TokenType.ERC20
          })
        }
        if (approvedSaleTokens.length !== saleTokens.length) {
          dispatch(setMarketSaleTokens(saleTokens))
        }

        const [offersRaw] = await provider.all([contract.getOffers()])
        const offers: IOffer[] = offersRaw.map((o: any) => ({
          escrow: o.escrow,
          type: o.offerTokenType,
          market: o.market,
          seller: o.seller,
          offerToken: o.offerToken,
          offerTokenId: o.offerTokenId,
          saleToken: o.saleToken,
          offerAmount: o.offerAmount,
          saleAmount: o.saleAmount,
          fee: o.fee,
          cooldown: o.cooldown.toNumber(),
        }))

        dispatch(setMarketOffers(offers))
      } catch (e) {
        log.error('Error querying for data', e);
      }
    };
    get();
    const interval = setInterval(() => {
      get();
    }, 900000);

    return () => {
      clearInterval(interval);
    };
  }, [chain]); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};

export default MarketUpdater;
