import { Chain } from '@wagmi/core';
import { ReactNode } from "react";

type NativeCurrency = {
  decimals: number,
  name: string,
  symbol: string,
  logo: ReactNode
}

export type EChain = Chain & {
  logo: ReactNode,
  nativeCurrency: NativeCurrency
}

const klaytn: EChain = {
  id: 8_217,
  name: "Klaytn",
  logo: "https://cryptologos.cc/logos/klaytn-klay-logo.png",
  network: "klaytn",
  nativeCurrency: {
    decimals: 18,
    name: "Klay",
    symbol: "KLAY",
    logo: "https://cryptologos.cc/logos/klaytn-klay-logo.png",
  },
  rpcUrls: {
    default: {
      http: [
        "https://public-node-api.klaytnapi.com/v1/cypress"
      ]
    },
  },
  blockExplorers: {
    default: { name: "BlockExplorer", url: "https://scope.klaytn.com/" },
  },
  testnet: false,
  // ens: {
  //  address: '0x4832D668C2c75Fa10C597FD19B116d2E1873ED69'
  //},
  //multicall: {
  //  address: '0x7f3aC7C283d7E6662D886F494f7bc6F1993cDacf',
  //  blockCreated: 9772745
  //}
};

const dfk: EChain = {
  id: 53_935,
  name: "DFK",
  logo: "https://defi-kingdoms.b-cdn.net/art-assets/tokens/jewel_token_x2.png",
  network: "DFK",
  nativeCurrency: {
    decimals: 18,
    name: "Jewel",
    symbol: "JEWEL",
    logo: "https://defi-kingdoms.b-cdn.net/art-assets/tokens/jewel_token_x2.png",
  },
  rpcUrls: {
    default: {
      http: [
        "https://avax-dfk.gateway.pokt.network/v1/lb/6244818c00b9f0003ad1b619//ext/bc/q2aTwKuyzgs8pynF7UXBZCU7DejbZbZ6EUyHr3JQzYgwNPUPi/rpc"
      ]
    },
  },
  blockExplorers: {
    default: { name: "BlockExplorer", url: "https://subnets.avax.network/defi-kingdoms/" },
  },
  testnet: false,
  // ens: {
  //  address: '0x4832D668C2c75Fa10C597FD19B116d2E1873ED69'
  //},
  //multicall: {
  //  address: '0x7f3aC7C283d7E6662D886F494f7bc6F1993cDacf',
  //  blockCreated: 9772745
  //}
};

const Chains = { klaytn, dfk }
export default Chains;