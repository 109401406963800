import { ExpandMore } from '@mui/icons-material';
import {
  Accordion, AccordionDetails, AccordionSummary,
  Avatar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import Logo from '../../assets/images/old-logo.jpg';


const FAQ = () => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Stack spacing={2} sx={{ paddingY: 4, width: sm ? 0.95 : 0.8, maxWidth: "md",  alignItems: 'center' }}>
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', marginBottom: 4 }}>
        <Avatar sx={{ minWidth: '72px', minHeight: '72px' }} src={Logo}/>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>Frequently Asked Questions</Typography>
        <Avatar sx={{ minWidth: '72px', minHeight: '72px' }} src={Logo}/>
      </Stack>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ flexShrink: 0 }}>
            What is Fox Market?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Fox Market is a utility intended to trustless-ly facilitate trades between buyers and sellers of
            various on-chain assets.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ flexShrink: 0 }}>
            How does Fox Market work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <Typography>
              Fox Market works through smart contracts. Using our intuitive interface, the seller is simply able to
              deploy a contract for the amount and type of asset they want to sell, along with their desired amount
              and type of stablecoin they are willing to accept as payment.
            </Typography>
            <Typography>
              When a suitable buyer deposits the necessary payment to fulfill the contract, the assets placed in the
              contract are released to the buyer, and the payment is released to the seller.
            </Typography>
            <Typography>
              The seller can also cancel the contract at any time before it is fulfilled to get their assets back.
            </Typography>
            <Stack>
              <Typography>
                Disclaimer: Due to how locked tokens are implemented, while deploying contracts for selling locked tokens,
                please note:
              </Typography>
              <Typography>
                1) Any unlocked tokens of the same type that you have in your wallet will also be placed in the contract
                (so make sure there isn't any in your wallet if you don't wish to sell them.
              </Typography>
              <Typography>
                2) You can NOT sell a partial amount of locked tokens. If you wish to sell, you will have to sell the
                full amount.
              </Typography>
            </Stack>

          </Stack>

        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ flexShrink: 0 }}>
            What are the fees?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            2% on all trades. The fees are deducted from the seller's final amount. As buyer-centric service, no fees
            are deducted from the buyer.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ maxWidth: 1, flexShrink: 0 }}>
            Want to know more, or request other tokens to be listed?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you have any other questions about our services, or if you want to ask for other tokens to be made
            available for trading on Fox Market, you can reach us on discord.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Stack>
  )
}

export default FAQ;
